import JsonRpc from "@statianzo/pmrpc";
import { request } from './request';
import { AuthSignInResponse } from "./currentuser";
import * as Cookies from 'es-cookie';
import { Deferred } from "./deffered";

interface checkOriginResponse {
    valid: boolean;
}

const rpc = new JsonRpc({
    destination: window.parent,
    methods: {
        signout: (options: any) => {
            return signout(options)
        },
        revoke: (options: any) => {
            return revoke(options)
        },
        checkOrigin: (options: any) => {
            return checkOrigin(options)
        },
        issueToken: (options: any) => {
            return issueToken(options)
        },
        loadToken: (options: any) => {
            return loadToken(options)
        },
        refreshToken: (options: any) => {
            return refreshToken(options)
        }
    },
    source: window
});

function signout(options?: any) {
    sessionStorage.removeItem('de_token');
    return true;
}

function revoke(options?: any) {
    signout(options);
    return request('post', "https://api.darkedges.com/iframe/v1/revoke", {}, options).then(response => {
        return response.json<checkOriginResponse>();
    })
}

function checkOrigin(options?: any) {
    return request('get', "https://api.darkedges.com/iframe/v1/checkOrigin").then(async response => {
        return await response.json<checkOriginResponse>();
    })
}

function issueToken(options?: any) {
    return request('get', "https://api.darkedges.com/iframe/v1/issueToken").then(async response => {
        const token = await response.json<AuthSignInResponse>();
        var issued_at = (new Date).getTime();
        var expires_at = issued_at + (1000 * token.expires_in)
        token.issued_at = issued_at;
        token.expires_at = expires_at;
        sessionStorage.setItem('de_token', JSON.stringify(token));
        return token;
    })
}

async function refreshToken(options?: any): Promise<AuthSignInResponse> {
    if (Cookies.get('iPlanetDirectoryPro')) {
        return await issueToken(options);
    } else {
        return {} as AuthSignInResponse;
    }
}

async function loadToken(options?: any): Promise<AuthSignInResponse> {
    const tokenVal = sessionStorage.getItem('de_token');
    let token: AuthSignInResponse;
    if (tokenVal) {
        token = JSON.parse(sessionStorage.getItem('de_token'));
        if (_tokenValid(token)) {
            return token;
        } else {
            return await refreshToken(options);
        }
    }
    return {} as AuthSignInResponse;
}

function _tokenValid(response: AuthSignInResponse) {
    return response.expires_at - (new Date).getTime() > 0 ? true : false;
}