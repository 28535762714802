var VERSION = '2.0';
var METHOD_NOT_FOUND = {
    code: -32601 /* MethodNotFound */,
    message: 'Method not found',
};
var buildResponse = function (id) { return function (result) { return ({
    jsonrpc: VERSION,
    id: id,
    result: result,
}); }; };
var buildErrorResponse = function (id) { return function (error) { return ({
    jsonrpc: VERSION,
    id: id,
    error: {
        code: error.code || -32603 /* InternalError */,
        message: error.message,
    },
}); }; };
var ensureArray = function (source) {
    return Array.isArray(source) ? source : [source];
};
var JsonRpc = /** @class */ (function () {
    function JsonRpc(_a) {
        var _this = this;
        var _b = _a === void 0 ? {} : _a, _c = _b.methods, methods = _c === void 0 ? {} : _c, source = _b.source, destination = _b.destination, origin = _b.origin;
        this.sequence = 0;
        this.deferreds = {};
        this.handleMessage = function (_a) {
            var data = _a.data, origin = _a.origin;
            if (!data)
                return;
            if (data.jsonrpc !== '2.0')
                return;
            if (_this.origin !== '*' && _this.origin !== origin)
                return;
            if ('method' in data) {
                _this.handleRequest(data).then(function (response) {
                    return _this.destination && _this.postMessage(_this.destination, response);
                });
            }
            else if ('result' in data || 'error' in data) {
                _this.handleResponse(data);
            }
        };
        this.methods = methods;
        this.destination = destination;
        this.origin = origin || '*';
        this.source = source;
        if (this.source) {
            this.mount(this.source);
        }
    }
    JsonRpc.prototype.apply = function (method, params) {
        var _this = this;
        if (!this.destination) {
            throw Error('Attempted to apply with no destination');
        }
        var id = this.sequence++;
        var promise = new Promise(function (resolve, reject) {
            _this.deferreds[id] = { resolve: resolve, reject: reject };
        });
        this.postMessage(this.destination, {
            id: id,
            jsonrpc: VERSION,
            method: method,
            params: params,
        });
        return promise;
    };
    JsonRpc.prototype.call = function (method) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        return this.apply(method, rest);
    };
    JsonRpc.prototype.mount = function (source) {
        this.source = source;
        source.addEventListener('message', this.handleMessage);
    };
    JsonRpc.prototype.unmount = function () {
        this.source &&
            this.source.removeEventListener('message', this.handleMessage);
    };
    JsonRpc.prototype.handleRequest = function (request) {
        var _this = this;
        return Promise.resolve()
            .then(function () {
            var method = _this.methods[request.method];
            return method
                ? method.apply(null, ensureArray(request.params))
                : Promise.reject(METHOD_NOT_FOUND);
        })
            .then(buildResponse(request.id), buildErrorResponse(request.id));
    };
    JsonRpc.prototype.handleResponse = function (response) {
        var deferred = this.deferreds[response.id];
        delete this.deferreds[response.id];
        if (!deferred)
            return;
        if (response.result) {
            deferred.resolve(response.result);
        }
        else {
            deferred.reject(Object.assign(Error(), response.error));
        }
    };
    JsonRpc.prototype.postMessage = function (target, message) {
        target = target; //Shadow to a Window
        var isWindow = target.window === target;
        target.postMessage(message, isWindow ? this.origin : undefined);
    };
    return JsonRpc;
}());

export default JsonRpc;
